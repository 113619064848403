module.exports = {
  routes: {
    '/': '/',
    '/estados/': '/state-crime/',
    '/homicidios-mujeres/': '/female-homicides/',
    '/feminicidio/': '/feminicides/',
    '/estados-diferencia/': '/state-changes/',
    '/estados-ranking/': '/state-ranking/',
    '/mapa-de-delincuencia/': '/violence-map/',
    '/mapa-clusters/': '/cluster-map/',
    '/municipios/': '/municipios/',
    '/municipios-mas-violentos/': '/most-violent-cities/',
    '/anomalias/': '/anomalies/',
    '/tendencias/': '/trends/',
    '/tendencias-estado/': '/trends-states/',
    '/envipe/': '/underreporting-crime/',
    '/infograficas/': '/infographics/',
    '/acerca/': '/about/',
    '/dev-404-page/': '/dev-404-page/',
    '/404/': '/404/',
    '/404.html': '/404.html',
    '/politica-privacidad/': '/privacy-policy/',
    '/turismo/': '/tourism/'
  },
  routes_inverted: {
    '/en/': '/',
    '/en/state-crime/': '/estados/',
    '/en/female-homicides/': '/homicidios-mujeres/',
    '/en/feminicides/': '/feminicidio/',
    '/en/state-changes/': '/estados-diferencia/',
    '/en/state-ranking/': '/estados-ranking/',
    '/en/violence-map/': '/mapa-de-delincuencia/',
    '/en/cluster-map/': '/mapa-clusters/',
    '/en/municipios/': '/municipios/',
    '/en/most-violent-cities/': '/municipios-mas-violentos/',
    '/en/anomalies/': '/anomalias/',
    '/en/trends/': '/tendencias/',
    '/en/trends-states/': '/tendencias-estado/',
    '/en/underreporting-crime/': '/envipe/',
    '/en/infographics/': '/infograficas/',
    '/en/about/': '/acerca/',
    '/en/dev-404-page/': '/dev-404-page/',
    '/en/404/': '/404/',
    '/en/404.html': '/404.html',
    '/en/privacy-policy/': '/politica-privacidad/',
    '/en/tourism/': '/turismo/'
  },
};
